// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** background, text and step colors **/
  --ion-background-color: #f8f9f3;
  --ion-background-color-rgb: 248, 249, 243;

  --ion-text-color: #00334b;
  --ion-text-color-rgb: 0, 51, 75;

  --ion-color-step-50: #ecefeb;
  --ion-color-step-100: #dfe5e2;
  --ion-color-step-150: #d3dbda;
  --ion-color-step-200: #c6d1d1;
  --ion-color-step-250: #bac8c9;
  --ion-color-step-300: #aebec1;
  --ion-color-step-350: #a1b4b8;
  --ion-color-step-400: #95aab0;
  --ion-color-step-450: #88a0a7;
  --ion-color-step-500: #7c969f;
  --ion-color-step-550: #708c97;
  --ion-color-step-600: #63828e;
  --ion-color-step-650: #577886;
  --ion-color-step-700: #4a6e7d;
  --ion-color-step-750: #3e6575;
  --ion-color-step-800: #325b6d;
  --ion-color-step-850: #255164;
  --ion-color-step-900: #19475c;
  --ion-color-step-950: #0c3d53;

  /** primary **/
  --ion-color-primary: #00334b;
  --ion-color-primary-rgb: 0, 51, 75;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #002d42;
  --ion-color-primary-tint: #1a475d;

  /** secondary **/
  --ion-color-secondary: #107c92;
  --ion-color-secondary-rgb: 16, 124, 146;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0e6d80;
  --ion-color-secondary-tint: #28899d;

  /** tertiary **/
  --ion-color-tertiary: #94c1c6;
  --ion-color-tertiary-rgb: 148, 193, 198;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #82aaae;
  --ion-color-tertiary-tint: #9fc7cc;

  /** tertiary 40 **/
  --ion-color-tertiary40: #d4f4eb;
  --ion-color-tertiary40-rgb: 212, 244, 235;
  --ion-color-tertiary40-contrast: #000000;
  --ion-color-tertiary40-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary40-shade: #bbd7cf;
  --ion-color-tertiary40-tint: #d8f5ed;

  /** success **/
  --ion-color-success: #2b7b2e;
  --ion-color-success-rgb: 43, 123, 46;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #266c28;
  --ion-color-success-tint: #408843;

  /** warning **/
  --ion-color-warning: #edb809;
  --ion-color-warning-rgb: 237, 184, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d1a208;
  --ion-color-warning-tint: #efbf22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #bc9f77;
  --ion-color-medium-rgb: 188, 159, 119;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #a58c69;
  --ion-color-medium-tint: #c3a985;

  /** light **/
  --ion-color-light: #ddd7c3;
  --ion-color-light-rgb: 221, 215, 195;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #c2bdac;
  --ion-color-light-tint: #e0dbc9;

  /** light 20% **/
  --ion-color-light20: #f8f9f3;
  --ion-color-light20-rgb: 248, 249, 243;
  --ion-color-light20-contrast: #000000;
  --ion-color-light20-contrast-rgb: 0, 0, 0;
  --ion-color-light20-shade: #dadbd6;
  --ion-color-light20-tint: #f9faf4;

  /** light 40% **/
  --ion-color-light40: #f8f4e8;
  --ion-color-light40-rgb: 248, 244, 232;
  --ion-color-light40-contrast: #000000;
  --ion-color-light40-contrast-rgb: 0, 0, 0;
  --ion-color-light40-shade: #dad7cc;
  --ion-color-light40-tint: #f9f5ea;
}

ion-content {
  --background: var(--ion-color-light20);
}

ion-item {
  --background: var(--ion-color-light20);
}

.ion-text-mute {
  color: var(--ion-color-step-400);
}

// tertiary 40%
.ion-color-tertiary40 {
  --ion-color-base: var(--ion-color-tertiary40);
  --ion-color-base-rgb: var(--ion-color-tertiary40-rgb);
  --ion-color-contrast: var(--ion-color-tertiary40-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary40-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary40-shade);
  --ion-color-tint: var(--ion-color-tertiary40-tint);
}

// light 20%
.ion-color-light20 {
  --ion-color-base: var(--ion-color-light20);
  --ion-color-base-rgb: var(--ion-color-light20-rgb);
  --ion-color-contrast: var(--ion-color-light20-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light20-contrast-rgb);
  --ion-color-shade: var(--ion-color-light20-shade);
  --ion-color-tint: var(--ion-color-light20-tint);
}

// light 40%
.ion-color-light40 {
  --ion-color-base: var(--ion-color-light40);
  --ion-color-base-rgb: var(--ion-color-light40-rgb);
  --ion-color-contrast: var(--ion-color-light40-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light40-contrast-rgb);
  --ion-color-shade: var(--ion-color-light40-shade);
  --ion-color-tint: var(--ion-color-light40-tint);
}
